import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Iso8601DateTime: any;
  Iterable: any;
  RoublePenniesAmmount: any;
  Upload: any;
  Uuid: any;
};

/** Accreditation status */
export enum AccreditationStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** Age type */
export enum AgeType {
  From_1To_3Years = 'from_1_to_3_years',
  From_3To_5Years = 'from_3_to_5_years',
  Less_1Year = 'less_1_year',
  More_5Years = 'more_5_years',
  More_5YearsWoLossesAndRevenueGrowth = 'more_5_years_wo_losses_and_revenue_growth',
  More_5YearsWoLossesOrRevenueGrowth = 'more_5_years_wo_losses_or_revenue_growth'
}

export type AgentCommissionReward = Node & {
  __typename?: 'AgentCommissionReward';
  _id: Scalars['Int'];
  amount: Scalars['RoublePenniesAmmount'];
  commissionRate: Scalars['Float'];
  created: Scalars['Iso8601DateTime'];
  deal: Deal;
  id: Scalars['ID'];
  source: Source;
  status: AgentCommissionRewardStatus;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for AgentCommissionReward. */
export type AgentCommissionRewardConnection = {
  __typename?: 'AgentCommissionRewardConnection';
  edges?: Maybe<Array<Maybe<AgentCommissionRewardEdge>>>;
  pageInfo: AgentCommissionRewardPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of AgentCommissionReward. */
export type AgentCommissionRewardEdge = {
  __typename?: 'AgentCommissionRewardEdge';
  cursor: Scalars['String'];
  node?: Maybe<AgentCommissionReward>;
};

/** Information about the current page. */
export type AgentCommissionRewardPageInfo = {
  __typename?: 'AgentCommissionRewardPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Agent commission reward status */
export enum AgentCommissionRewardStatus {
  Paid = 'paid',
  Pending = 'pending'
}

export type Application = Node & {
  __typename?: 'Application';
  _id: Scalars['Int'];
  advanceRate?: Maybe<Scalars['Float']>;
  amoCrmId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['RoublePenniesAmmount']>;
  channel?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contacts?: Maybe<ContactConnection>;
  created: Scalars['Iso8601DateTime'];
  customerCompany?: Maybe<CustomerCompany>;
  deal?: Maybe<Deal>;
  documents?: Maybe<DocumentConnection>;
  durationMonths?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  fio: Scalars['String'];
  id: Scalars['ID'];
  leasingSubjectCategories?: Maybe<LeasingSubjectCategoryConnection>;
  lossReason?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  reconciliations?: Maybe<ReconciliationConnection>;
  reconciliationsStatus: ApplicationReconciliationsStatus;
  source: Source;
  sourceData?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  user?: Maybe<User>;
  utmSource?: Maybe<Scalars['String']>;
};


export type ApplicationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<ContactFilter_Created>>>;
  email?: InputMaybe<Scalars['String']>;
  fio?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ContactFilter_Order>>>;
  phone?: InputMaybe<Scalars['String']>;
  verificationStatus?: InputMaybe<Scalars['String']>;
};


export type ApplicationDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ApplicationLeasingSubjectCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ApplicationReconciliationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  application_id?: InputMaybe<Scalars['Int']>;
  application_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  createdBy_id?: InputMaybe<Scalars['Int']>;
  createdBy_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  isActual?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ReconciliationFilter_Order>>>;
};

/** Connection for Application. */
export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  edges?: Maybe<Array<Maybe<ApplicationEdge>>>;
  pageInfo: ApplicationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Application. */
export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Application>;
};

export type ApplicationFilter_Created = {
  after?: InputMaybe<Scalars['Iso8601DateTime']>;
  before?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_after?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_before?: InputMaybe<Scalars['Iso8601DateTime']>;
};

export type ApplicationManagerDataType = {
  additionalPhone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fio: Scalars['String'];
  phone: Scalars['String'];
};

/** Information about the current page. */
export type ApplicationPageInfo = {
  __typename?: 'ApplicationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Reconciliations status */
export enum ApplicationReconciliationsStatus {
  Initial = 'initial',
  Negotiations = 'negotiations'
}

export type BankDetail = Node & {
  __typename?: 'BankDetail';
  _id: Scalars['Int'];
  account: Scalars['String'];
  bic: Scalars['String'];
  correspondentAccount: Scalars['String'];
  created: Scalars['Iso8601DateTime'];
  customerCompany?: Maybe<CustomerCompany>;
  id: Scalars['ID'];
  name: Scalars['String'];
  supplierCompany?: Maybe<SupplierCompany>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for BankDetail. */
export type BankDetailConnection = {
  __typename?: 'BankDetailConnection';
  edges?: Maybe<Array<Maybe<BankDetailEdge>>>;
  pageInfo: BankDetailPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of BankDetail. */
export type BankDetailEdge = {
  __typename?: 'BankDetailEdge';
  cursor: Scalars['String'];
  node?: Maybe<BankDetail>;
};

/** Information about the current page. */
export type BankDetailPageInfo = {
  __typename?: 'BankDetailPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type BkiPayments = {
  id: Scalars['Int'];
  sentAt: Scalars['Iso8601DateTime'];
  state: Scalars['String'];
};

export type Company = Node & {
  __typename?: 'Company';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  dadata?: Maybe<Scalars['Iterable']>;
  id: Scalars['ID'];
  inn: Scalars['String'];
  kpp?: Maybe<Scalars['String']>;
  lastDadataRequestDate?: Maybe<Scalars['Iso8601DateTime']>;
  lastDadataRequestUser?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  needsUpdate: Scalars['Boolean'];
  okpo?: Maybe<Scalars['String']>;
  opf?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['Iso8601DateTime']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for Company. */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  pageInfo: CompanyPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Company. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<Company>;
};

/** Information about the current page. */
export type CompanyPageInfo = {
  __typename?: 'CompanyPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Contact = Node & {
  __typename?: 'Contact';
  _id: Scalars['Int'];
  applications?: Maybe<ApplicationConnection>;
  created: Scalars['Iso8601DateTime'];
  documents?: Maybe<DocumentConnection>;
  email?: Maybe<Scalars['String']>;
  fio: Scalars['String'];
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  publicId: Scalars['String'];
  selfie?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  verificationStatus: Scalars['String'];
};


export type ContactApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<ApplicationFilter_Created>>>;
  customerCompany_inn?: InputMaybe<Scalars['String']>;
  customerCompany_name?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  source_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type ContactDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Connection for Contact. */
export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges?: Maybe<Array<Maybe<ContactEdge>>>;
  pageInfo: ContactPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Contact. */
export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<Contact>;
};

export type ContactFilter_Created = {
  after?: InputMaybe<Scalars['Iso8601DateTime']>;
  before?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_after?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_before?: InputMaybe<Scalars['Iso8601DateTime']>;
};

export type ContactFilter_Order = {
  fio?: InputMaybe<Scalars['String']>;
};

export type ContactInfo = Node & {
  __typename?: 'ContactInfo';
  _id: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  created: Scalars['Iso8601DateTime'];
  email?: Maybe<Scalars['String']>;
  fio: Scalars['String'];
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
  supplierCompany?: Maybe<SupplierCompany>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for ContactInfo. */
export type ContactInfoConnection = {
  __typename?: 'ContactInfoConnection';
  edges?: Maybe<Array<Maybe<ContactInfoEdge>>>;
  pageInfo: ContactInfoPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of ContactInfo. */
export type ContactInfoEdge = {
  __typename?: 'ContactInfoEdge';
  cursor: Scalars['String'];
  node?: Maybe<ContactInfo>;
};

/** Information about the current page. */
export type ContactInfoPageInfo = {
  __typename?: 'ContactInfoPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type ContactPageInfo = {
  __typename?: 'ContactPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CustomerCompany = Node & {
  __typename?: 'CustomerCompany';
  _id: Scalars['Int'];
  applications?: Maybe<ApplicationConnection>;
  bankDetails?: Maybe<BankDetailConnection>;
  created: Scalars['Iso8601DateTime'];
  dadata?: Maybe<Scalars['Iterable']>;
  deals?: Maybe<DealConnection>;
  documents?: Maybe<DocumentConnection>;
  edoCustomValue?: Maybe<Scalars['String']>;
  edoStatus?: Maybe<Scalars['Boolean']>;
  edoTypes?: Maybe<EdoTypeConnection>;
  id: Scalars['ID'];
  inn: Scalars['String'];
  kpp?: Maybe<Scalars['String']>;
  lastDadataRequestDate?: Maybe<Scalars['Iso8601DateTime']>;
  lastDadataRequestUser?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  needsUpdate: Scalars['Boolean'];
  okpo?: Maybe<Scalars['String']>;
  opf?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['Iso8601DateTime']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};


export type CustomerCompanyApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<ApplicationFilter_Created>>>;
  customerCompany_inn?: InputMaybe<Scalars['String']>;
  customerCompany_name?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  source_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type CustomerCompanyBankDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CustomerCompanyDealsArgs = {
  after?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Array<InputMaybe<DealFilter_Amount>>>;
  application_source_id?: InputMaybe<Scalars['Int']>;
  application_source_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<DealFilter_Created>>>;
  customerCompany_inn?: InputMaybe<Scalars['String']>;
  customerCompany_name?: InputMaybe<Scalars['String']>;
  dealSupplies_supplierCompany_id?: InputMaybe<Scalars['Int']>;
  dealSupplies_supplierCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  order?: InputMaybe<Array<InputMaybe<DealFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supplierCompanies?: InputMaybe<Scalars['String']>;
  supplierCompanies_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supplyingStatus?: InputMaybe<Scalars['String']>;
  supplyingStatus_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user?: InputMaybe<Scalars['String']>;
  user_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerCompanyDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CustomerCompanyEdoTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EdoTypeFilter_Order>>>;
};

/** Connection for CustomerCompany. */
export type CustomerCompanyConnection = {
  __typename?: 'CustomerCompanyConnection';
  edges?: Maybe<Array<Maybe<CustomerCompanyEdge>>>;
  pageInfo: CustomerCompanyPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of CustomerCompany. */
export type CustomerCompanyEdge = {
  __typename?: 'CustomerCompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomerCompany>;
};

export type CustomerCompanyFilter_Exists = {
  deals?: InputMaybe<Scalars['Boolean']>;
};

/** Information about the current page. */
export type CustomerCompanyPageInfo = {
  __typename?: 'CustomerCompanyPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Deal = Node & {
  __typename?: 'Deal';
  _id: Scalars['Int'];
  advancePaymentDate?: Maybe<Scalars['Iso8601DateTime']>;
  advanceRate: Scalars['Float'];
  agentCommissionRewards?: Maybe<AgentCommissionRewardConnection>;
  amount: Scalars['RoublePenniesAmmount'];
  application: Application;
  comissionRate: Scalars['Float'];
  contractDate?: Maybe<Scalars['Iso8601DateTime']>;
  contractNumber?: Maybe<Scalars['String']>;
  created: Scalars['Iso8601DateTime'];
  customerCompany: CustomerCompany;
  dealSupplies?: Maybe<DealSupplyConnection>;
  documents?: Maybe<DocumentConnection>;
  durationMonths: Scalars['Int'];
  guarantors?: Maybe<GuarantorConnection>;
  id: Scalars['ID'];
  insuranceContract?: Maybe<Scalars['String']>;
  insuranceContractDate?: Maybe<Scalars['Iso8601DateTime']>;
  insuranceKind?: Maybe<DealInsuranceKind>;
  insuranceRate: Scalars['Float'];
  interestRate: Scalars['Float'];
  kind?: Maybe<Kind>;
  leasingSubjectCategories?: Maybe<LeasingSubjectCategoryConnection>;
  paymentReceptionStatusSetAt?: Maybe<Scalars['Iso8601DateTime']>;
  paymentSchedule?: Maybe<Scalars['Iterable']>;
  reportingBkiGuarantorContractStatus: ReportingBkiGuarantorContractStatus;
  reportingBkiLeasingContractStatus: ReportingBkiLeasingContractStatus;
  reportingBkiPaymentsSent: Scalars['Iterable'];
  reportingFedresStatus: ReportingFedresStatus;
  reportingRfmStatus: ReportingRfmStatus;
  signDate?: Maybe<Scalars['Iso8601DateTime']>;
  status: DealStatus;
  supplierCompanies?: Maybe<SupplierCompanyConnection>;
  supplyingStatus?: Maybe<SupplyingStatus>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['String']>;
  vatRate: Scalars['Float'];
};


export type DealAgentCommissionRewardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deal?: InputMaybe<Scalars['String']>;
  deal_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DealDealSuppliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type DealDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type DealGuarantorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type DealLeasingSubjectCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DealSupplierCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inn?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
};

/** Connection for Deal. */
export type DealConnection = {
  __typename?: 'DealConnection';
  edges?: Maybe<Array<Maybe<DealEdge>>>;
  pageInfo: DealPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Deal. */
export type DealEdge = {
  __typename?: 'DealEdge';
  cursor: Scalars['String'];
  node?: Maybe<Deal>;
};

export type DealFilter_Amount = {
  between?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
};

export type DealFilter_Created = {
  after?: InputMaybe<Scalars['Iso8601DateTime']>;
  before?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_after?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_before?: InputMaybe<Scalars['Iso8601DateTime']>;
};

export type DealFilter_Order = {
  created?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Deal insurance kind */
export enum DealInsuranceKind {
  WithInsurance = 'with_insurance',
  WithoutInsurance = 'without_insurance'
}

/** Information about the current page. */
export type DealPageInfo = {
  __typename?: 'DealPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Deal status */
export enum DealStatus {
  Activation = 'activation',
  Cancelled = 'cancelled',
  Closed = 'closed',
  PaymentReception = 'payment_reception',
  Signing = 'signing'
}

export type DealSupply = Node & {
  __typename?: 'DealSupply';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  deal: Deal;
  deleted: Scalars['Boolean'];
  documents?: Maybe<DocumentConnection>;
  frameContractDocument?: Maybe<Document>;
  id: Scalars['ID'];
  mode: DealSupplyMode;
  status?: Maybe<DealSupplyStatus>;
  supplierCompany: SupplierCompany;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};


export type DealSupplyDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Connection for DealSupply. */
export type DealSupplyConnection = {
  __typename?: 'DealSupplyConnection';
  edges?: Maybe<Array<Maybe<DealSupplyEdge>>>;
  pageInfo: DealSupplyPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of DealSupply. */
export type DealSupplyEdge = {
  __typename?: 'DealSupplyEdge';
  cursor: Scalars['String'];
  node?: Maybe<DealSupply>;
};

/** Deal supply mode */
export enum DealSupplyMode {
  Frame = 'frame',
  Single = 'single'
}

/** Information about the current page. */
export type DealSupplyPageInfo = {
  __typename?: 'DealSupplyPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Deal Supply status */
export enum DealSupplyStatus {
  Paid = 'paid',
  Pending = 'pending',
  ShipmentDocs = 'shipment_docs'
}

export type Document = Node & {
  __typename?: 'Document';
  _id: Scalars['Int'];
  agentCommissionReward?: Maybe<AgentCommissionReward>;
  application?: Maybe<Application>;
  contact?: Maybe<Contact>;
  created: Scalars['Iso8601DateTime'];
  customerCompany?: Maybe<CustomerCompany>;
  deal?: Maybe<Deal>;
  filePath: Scalars['String'];
  guarantor?: Maybe<Guarantor>;
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  originalName: Scalars['String'];
  size: Scalars['Int'];
  source?: Maybe<Source>;
  supplierCompany?: Maybe<SupplierCompany>;
  type: DocumentType;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  user?: Maybe<User>;
};

/** Connection for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  pageInfo: DocumentPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Document. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  cursor: Scalars['String'];
  node?: Maybe<Document>;
};

export type DocumentFilterByEntityType = {
  entityId: Scalars['Int'];
  entityType: Scalars['String'];
  type: Scalars['String'];
};

/** Information about the current page. */
export type DocumentPageInfo = {
  __typename?: 'DocumentPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type DocumentType = Node & {
  __typename?: 'DocumentType';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  entity: DocumentTypeEntity;
  id: Scalars['ID'];
  type: Scalars['String'];
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for DocumentType. */
export type DocumentTypeConnection = {
  __typename?: 'DocumentTypeConnection';
  edges?: Maybe<Array<Maybe<DocumentTypeEdge>>>;
  pageInfo: DocumentTypePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of DocumentType. */
export type DocumentTypeEdge = {
  __typename?: 'DocumentTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<DocumentType>;
};

/** Entity associated with the Document Type */
export enum DocumentTypeEntity {
  AgentCommissionReward = 'agent_commission_reward',
  Application = 'application',
  Contact = 'contact',
  CustomerCompany = 'customer_company',
  Deal = 'deal',
  DealSupply = 'deal_supply',
  Guarantor = 'guarantor',
  Source = 'source',
  SupplierCompany = 'supplier_company'
}

/** Information about the current page. */
export type DocumentTypePageInfo = {
  __typename?: 'DocumentTypePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type EdoType = Node & {
  __typename?: 'EdoType';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for EdoType. */
export type EdoTypeConnection = {
  __typename?: 'EdoTypeConnection';
  edges?: Maybe<Array<Maybe<EdoTypeEdge>>>;
  pageInfo: EdoTypePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of EdoType. */
export type EdoTypeEdge = {
  __typename?: 'EdoTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<EdoType>;
};

export type EdoTypeFilter_Order = {
  sort?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type EdoTypePageInfo = {
  __typename?: 'EdoTypePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type FedResourceCategory = Node & {
  __typename?: 'FedResourceCategory';
  _id: Scalars['Int'];
  code: Scalars['String'];
  created: Scalars['Iso8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for FedResourceCategory. */
export type FedResourceCategoryConnection = {
  __typename?: 'FedResourceCategoryConnection';
  edges?: Maybe<Array<Maybe<FedResourceCategoryEdge>>>;
  pageInfo: FedResourceCategoryPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of FedResourceCategory. */
export type FedResourceCategoryEdge = {
  __typename?: 'FedResourceCategoryEdge';
  cursor: Scalars['String'];
  node?: Maybe<FedResourceCategory>;
};

/** Information about the current page. */
export type FedResourceCategoryPageInfo = {
  __typename?: 'FedResourceCategoryPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type FsspContactInfo = Node & {
  __typename?: 'FsspContactInfo';
  _id?: Maybe<Scalars['Int']>;
  contact: Contact;
  created: Scalars['Iso8601DateTime'];
  id: Scalars['ID'];
  result: Scalars['Iterable'];
  status: Scalars['String'];
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  user: User;
};

/** Connection for FsspContactInfo. */
export type FsspContactInfoConnection = {
  __typename?: 'FsspContactInfoConnection';
  edges?: Maybe<Array<Maybe<FsspContactInfoEdge>>>;
  pageInfo: FsspContactInfoPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of FsspContactInfo. */
export type FsspContactInfoEdge = {
  __typename?: 'FsspContactInfoEdge';
  cursor: Scalars['String'];
  node?: Maybe<FsspContactInfo>;
};

export type FsspContactInfoFilter_Created = {
  after?: InputMaybe<Scalars['Iso8601DateTime']>;
  before?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_after?: InputMaybe<Scalars['Iso8601DateTime']>;
  strictly_before?: InputMaybe<Scalars['Iso8601DateTime']>;
};

/** Information about the current page. */
export type FsspContactInfoPageInfo = {
  __typename?: 'FsspContactInfoPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Guarantor = Node & {
  __typename?: 'Guarantor';
  _id: Scalars['Int'];
  contact?: Maybe<Contact>;
  contractNumber: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  deal: Deal;
  documents?: Maybe<DocumentConnection>;
  guarantorCompany?: Maybe<GuarantorCompany>;
  id: Scalars['ID'];
  signMethod?: Maybe<GuarantorSignMethod>;
  signed?: Maybe<Scalars['Iso8601DateTime']>;
  status: GuarantorStatus;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  uuid?: Maybe<Scalars['String']>;
};


export type GuarantorDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type GuarantorCompany = Node & {
  __typename?: 'GuarantorCompany';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  dadata?: Maybe<Scalars['Iterable']>;
  id: Scalars['ID'];
  inn: Scalars['String'];
  kpp?: Maybe<Scalars['String']>;
  lastDadataRequestDate?: Maybe<Scalars['Iso8601DateTime']>;
  lastDadataRequestUser?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  needsUpdate: Scalars['Boolean'];
  okpo?: Maybe<Scalars['String']>;
  opf?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['Iso8601DateTime']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for GuarantorCompany. */
export type GuarantorCompanyConnection = {
  __typename?: 'GuarantorCompanyConnection';
  edges?: Maybe<Array<Maybe<GuarantorCompanyEdge>>>;
  pageInfo: GuarantorCompanyPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of GuarantorCompany. */
export type GuarantorCompanyEdge = {
  __typename?: 'GuarantorCompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<GuarantorCompany>;
};

/** Information about the current page. */
export type GuarantorCompanyPageInfo = {
  __typename?: 'GuarantorCompanyPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Connection for Guarantor. */
export type GuarantorConnection = {
  __typename?: 'GuarantorConnection';
  edges?: Maybe<Array<Maybe<GuarantorEdge>>>;
  pageInfo: GuarantorPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Guarantor. */
export type GuarantorEdge = {
  __typename?: 'GuarantorEdge';
  cursor: Scalars['String'];
  node?: Maybe<Guarantor>;
};

/** Information about the current page. */
export type GuarantorPageInfo = {
  __typename?: 'GuarantorPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Guarantor sign method */
export enum GuarantorSignMethod {
  Edo = 'edo',
  Otp = 'otp',
  Personal = 'personal'
}

/** Guarantor status */
export enum GuarantorStatus {
  NotSigned = 'not_signed',
  Signed = 'signed'
}

/** Kind */
export enum Kind {
  Medicine = 'medicine',
  Regular = 'regular'
}

export type LeasingSubjectCategory = Node & {
  __typename?: 'LeasingSubjectCategory';
  _id: Scalars['Int'];
  categoryGroup?: Maybe<LeasingSubjectCategoryGroup>;
  created: Scalars['Iso8601DateTime'];
  fedResourceCategory?: Maybe<FedResourceCategory>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for LeasingSubjectCategory. */
export type LeasingSubjectCategoryConnection = {
  __typename?: 'LeasingSubjectCategoryConnection';
  edges?: Maybe<Array<Maybe<LeasingSubjectCategoryEdge>>>;
  pageInfo: LeasingSubjectCategoryPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of LeasingSubjectCategory. */
export type LeasingSubjectCategoryEdge = {
  __typename?: 'LeasingSubjectCategoryEdge';
  cursor: Scalars['String'];
  node?: Maybe<LeasingSubjectCategory>;
};

export type LeasingSubjectCategoryGroup = Node & {
  __typename?: 'LeasingSubjectCategoryGroup';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for LeasingSubjectCategoryGroup. */
export type LeasingSubjectCategoryGroupConnection = {
  __typename?: 'LeasingSubjectCategoryGroupConnection';
  edges?: Maybe<Array<Maybe<LeasingSubjectCategoryGroupEdge>>>;
  pageInfo: LeasingSubjectCategoryGroupPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of LeasingSubjectCategoryGroup. */
export type LeasingSubjectCategoryGroupEdge = {
  __typename?: 'LeasingSubjectCategoryGroupEdge';
  cursor: Scalars['String'];
  node?: Maybe<LeasingSubjectCategoryGroup>;
};

/** Information about the current page. */
export type LeasingSubjectCategoryGroupPageInfo = {
  __typename?: 'LeasingSubjectCategoryGroupPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type LeasingSubjectCategoryPageInfo = {
  __typename?: 'LeasingSubjectCategoryPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Liquidity type */
export enum LiquidityType {
  LiquidHighRisk = 'liquid_high_risk',
  LiquidLowRisk = 'liquid_low_risk',
  NotLiquid = 'not_liquid'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds a agent commission reward. */
  addAgentCommissionReward?: Maybe<AddAgentCommissionRewardPayload>;
  /** Adds an application. */
  addApplication?: Maybe<AddApplicationPayload>;
  /** Adds bank details. */
  addBankDetail?: Maybe<AddBankDetailPayload>;
  /** Adds a company. */
  addCompany?: Maybe<AddCompanyPayload>;
  /** Adds a Contact. */
  addContact?: Maybe<AddContactPayload>;
  /** Adds a leasing subject category. */
  addContactInfo?: Maybe<AddContactInfoPayload>;
  /** Adds contact to application. */
  addContactToApplication?: Maybe<AddContactToApplicationPayload>;
  /** Adds a deal. */
  addDeal?: Maybe<AddDealPayload>;
  /** Adds deal supply. */
  addDealSupply?: Maybe<AddDealSupplyPayload>;
  /** Adds document type. */
  addDocumentType?: Maybe<AddDocumentTypePayload>;
  /** Looks for information about contact on FSSP website */
  addFsspContactInfo?: Maybe<AddFsspContactInfoPayload>;
  /** Adds a guarantor. */
  addGuarantor?: Maybe<AddGuarantorPayload>;
  /** Adds a guarantor company. */
  addGuarantorCompany?: Maybe<AddGuarantorCompanyPayload>;
  /** Adds guarantor to deal. */
  addGuarantorToDeal?: Maybe<AddGuarantorToDealPayload>;
  /** Adds a leasing subject category. */
  addLeasingSubjectCategory?: Maybe<AddLeasingSubjectCategoryPayload>;
  /** Adds a partnership application. */
  addPartnershipApplication?: Maybe<AddPartnershipApplicationPayload>;
  /** Adds a reconciliation for an application. */
  addReconciliation?: Maybe<AddReconciliationPayload>;
  /** Adds a source. */
  addSource?: Maybe<AddSourcePayload>;
  /** Adds a supplier company. */
  addSupplierCompany?: Maybe<AddSupplierCompanyPayload>;
  /** Adds a guarantor with company. */
  addWithCompanyGuarantor?: Maybe<AddWithCompanyGuarantorPayload>;
  /** Clear supplier api token (disable) */
  clearTokenSource?: Maybe<ClearTokenSourcePayload>;
  /** Logins a User. */
  loginUser?: Maybe<LoginUserPayload>;
  /** Adds a supplier company. */
  refreshFromDadataCustomerCompany?: Maybe<RefreshFromDadataCustomerCompanyPayload>;
  /** Adds a supplier company. */
  refreshFromDadataGuarantorCompany?: Maybe<RefreshFromDadataGuarantorCompanyPayload>;
  /** Adds a supplier company. */
  refreshFromDadataSupplierCompany?: Maybe<RefreshFromDadataSupplierCompanyPayload>;
  /** Looks for information about contact on FSSP website */
  refreshFsspContactInfo?: Maybe<RefreshFsspContactInfoPayload>;
  /** RefreshTokens a User. */
  refreshTokenUser?: Maybe<RefreshTokenUserPayload>;
  /** Regenerate supplier api token */
  regenerateTokenSource?: Maybe<RegenerateTokenSourcePayload>;
  /** Deletes a agent commission reward. */
  removeAgentCommissionReward?: Maybe<RemoveAgentCommissionRewardPayload>;
  /** Deletes application. */
  removeApplication?: Maybe<RemoveApplicationPayload>;
  /** Deletes bank detail. */
  removeBankDetail?: Maybe<RemoveBankDetailPayload>;
  /** Remove company from reconciliation. */
  removeCompanyFromReconciliation?: Maybe<RemoveCompanyFromReconciliationPayload>;
  /** Deletes Contact */
  removeContact?: Maybe<RemoveContactPayload>;
  /** Deletes contact from application. */
  removeContactFromApplication?: Maybe<RemoveContactFromApplicationPayload>;
  /** Remove contact from reconciliation. */
  removeContactFromReconciliation?: Maybe<RemoveContactFromReconciliationPayload>;
  /** Deletes Contact info. */
  removeContactInfo?: Maybe<RemoveContactInfoPayload>;
  /** Deletes deal supply. */
  removeDealSupply?: Maybe<RemoveDealSupplyPayload>;
  /** Deletes document. */
  removeDocument?: Maybe<RemoveDocumentPayload>;
  /** Deletes document type. */
  removeDocumentType?: Maybe<RemoveDocumentTypePayload>;
  /** Deletes Guarantor */
  removeGuarantor?: Maybe<RemoveGuarantorPayload>;
  /** Deletes leasing subject category. */
  removeLeasingSubjectCategory?: Maybe<RemoveLeasingSubjectCategoryPayload>;
  /** Deletes reconciliation. */
  removeReconciliation?: Maybe<RemoveReconciliationPayload>;
  /** Deletes a source. */
  removeSource?: Maybe<RemoveSourcePayload>;
  /** Updates a agent commission reward. */
  updateAgentCommissionReward?: Maybe<UpdateAgentCommissionRewardPayload>;
  /** Updates application. */
  updateApplication?: Maybe<UpdateApplicationPayload>;
  /** Updates a Bank account. */
  updateBankDetail?: Maybe<UpdateBankDetailPayload>;
  /** Updates a company. */
  updateCompany?: Maybe<UpdateCompanyPayload>;
  /** Updates a Contact. */
  updateContact?: Maybe<UpdateContactPayload>;
  /** Updates a leasing subject category. */
  updateContactInfo?: Maybe<UpdateContactInfoPayload>;
  /** Updates a Customer company. */
  updateCustomerCompany?: Maybe<UpdateCustomerCompanyPayload>;
  /** Updates a deal. */
  updateDeal?: Maybe<UpdateDealPayload>;
  /** Updates deal supply. */
  updateDealSupply?: Maybe<UpdateDealSupplyPayload>;
  /** Updates a guarantor. */
  updateGuarantor?: Maybe<UpdateGuarantorPayload>;
  /** Updates a leasing subject category. */
  updateLeasingSubjectCategory?: Maybe<UpdateLeasingSubjectCategoryPayload>;
  /** Updates fera and customer statuses of reconciliation. */
  updateReconciliation?: Maybe<UpdateReconciliationPayload>;
  /** Updates a source. */
  updateSource?: Maybe<UpdateSourcePayload>;
  /** Updates a ssupplier company. */
  updateSupplierCompany?: Maybe<UpdateSupplierCompanyPayload>;
  /** Uploads document by customer form for partner application. */
  uploadCustomerFormApplicationDocument?: Maybe<UploadCustomerFormApplicationDocumentPayload>;
  /** Uploads document. */
  uploadDocument?: Maybe<UploadDocumentPayload>;
  /** Uploads document for partner application. */
  uploadPartnerApplicationDocument?: Maybe<UploadPartnerApplicationDocumentPayload>;
};


export type MutationAddAgentCommissionRewardArgs = {
  input: AddAgentCommissionRewardInput;
};


export type MutationAddApplicationArgs = {
  input: AddApplicationInput;
};


export type MutationAddBankDetailArgs = {
  input: AddBankDetailInput;
};


export type MutationAddCompanyArgs = {
  input: AddCompanyInput;
};


export type MutationAddContactArgs = {
  input: AddContactInput;
};


export type MutationAddContactInfoArgs = {
  input: AddContactInfoInput;
};


export type MutationAddContactToApplicationArgs = {
  input: AddContactToApplicationInput;
};


export type MutationAddDealArgs = {
  input: AddDealInput;
};


export type MutationAddDealSupplyArgs = {
  input: AddDealSupplyInput;
};


export type MutationAddDocumentTypeArgs = {
  input: AddDocumentTypeInput;
};


export type MutationAddFsspContactInfoArgs = {
  input: AddFsspContactInfoInput;
};


export type MutationAddGuarantorArgs = {
  input: AddGuarantorInput;
};


export type MutationAddGuarantorCompanyArgs = {
  input: AddGuarantorCompanyInput;
};


export type MutationAddGuarantorToDealArgs = {
  input: AddGuarantorToDealInput;
};


export type MutationAddLeasingSubjectCategoryArgs = {
  input: AddLeasingSubjectCategoryInput;
};


export type MutationAddPartnershipApplicationArgs = {
  input: AddPartnershipApplicationInput;
};


export type MutationAddReconciliationArgs = {
  input: AddReconciliationInput;
};


export type MutationAddSourceArgs = {
  input: AddSourceInput;
};


export type MutationAddSupplierCompanyArgs = {
  input: AddSupplierCompanyInput;
};


export type MutationAddWithCompanyGuarantorArgs = {
  input: AddWithCompanyGuarantorInput;
};


export type MutationClearTokenSourceArgs = {
  input: ClearTokenSourceInput;
};


export type MutationLoginUserArgs = {
  input: LoginUserInput;
};


export type MutationRefreshFromDadataCustomerCompanyArgs = {
  input: RefreshFromDadataCustomerCompanyInput;
};


export type MutationRefreshFromDadataGuarantorCompanyArgs = {
  input: RefreshFromDadataGuarantorCompanyInput;
};


export type MutationRefreshFromDadataSupplierCompanyArgs = {
  input: RefreshFromDadataSupplierCompanyInput;
};


export type MutationRefreshFsspContactInfoArgs = {
  input: RefreshFsspContactInfoInput;
};


export type MutationRefreshTokenUserArgs = {
  input: RefreshTokenUserInput;
};


export type MutationRegenerateTokenSourceArgs = {
  input: RegenerateTokenSourceInput;
};


export type MutationRemoveAgentCommissionRewardArgs = {
  input: RemoveAgentCommissionRewardInput;
};


export type MutationRemoveApplicationArgs = {
  input: RemoveApplicationInput;
};


export type MutationRemoveBankDetailArgs = {
  input: RemoveBankDetailInput;
};


export type MutationRemoveCompanyFromReconciliationArgs = {
  input: RemoveCompanyFromReconciliationInput;
};


export type MutationRemoveContactArgs = {
  input: RemoveContactInput;
};


export type MutationRemoveContactFromApplicationArgs = {
  input: RemoveContactFromApplicationInput;
};


export type MutationRemoveContactFromReconciliationArgs = {
  input: RemoveContactFromReconciliationInput;
};


export type MutationRemoveContactInfoArgs = {
  input: RemoveContactInfoInput;
};


export type MutationRemoveDealSupplyArgs = {
  input: RemoveDealSupplyInput;
};


export type MutationRemoveDocumentArgs = {
  input: RemoveDocumentInput;
};


export type MutationRemoveDocumentTypeArgs = {
  input: RemoveDocumentTypeInput;
};


export type MutationRemoveGuarantorArgs = {
  input: RemoveGuarantorInput;
};


export type MutationRemoveLeasingSubjectCategoryArgs = {
  input: RemoveLeasingSubjectCategoryInput;
};


export type MutationRemoveReconciliationArgs = {
  input: RemoveReconciliationInput;
};


export type MutationRemoveSourceArgs = {
  input: RemoveSourceInput;
};


export type MutationUpdateAgentCommissionRewardArgs = {
  input: UpdateAgentCommissionRewardInput;
};


export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};


export type MutationUpdateBankDetailArgs = {
  input: UpdateBankDetailInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateContactInfoArgs = {
  input: UpdateContactInfoInput;
};


export type MutationUpdateCustomerCompanyArgs = {
  input: UpdateCustomerCompanyInput;
};


export type MutationUpdateDealArgs = {
  input: UpdateDealInput;
};


export type MutationUpdateDealSupplyArgs = {
  input: UpdateDealSupplyInput;
};


export type MutationUpdateGuarantorArgs = {
  input: UpdateGuarantorInput;
};


export type MutationUpdateLeasingSubjectCategoryArgs = {
  input: UpdateLeasingSubjectCategoryInput;
};


export type MutationUpdateReconciliationArgs = {
  input: UpdateReconciliationInput;
};


export type MutationUpdateSourceArgs = {
  input: UpdateSourceInput;
};


export type MutationUpdateSupplierCompanyArgs = {
  input: UpdateSupplierCompanyInput;
};


export type MutationUploadCustomerFormApplicationDocumentArgs = {
  input: UploadCustomerFormApplicationDocumentInput;
};


export type MutationUploadDocumentArgs = {
  input: UploadDocumentInput;
};


export type MutationUploadPartnerApplicationDocumentArgs = {
  input: UploadPartnerApplicationDocumentInput;
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID'];
};

export type PartnershipApplication = Node & {
  __typename?: 'PartnershipApplication';
  companyName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fio: Scalars['String'];
  id: Scalars['ID'];
  inn: Scalars['String'];
  phone: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  agentCommissionReward?: Maybe<AgentCommissionReward>;
  agentCommissionRewards?: Maybe<AgentCommissionRewardConnection>;
  application?: Maybe<Application>;
  applications?: Maybe<ApplicationConnection>;
  bankDetail?: Maybe<BankDetail>;
  bankDetails?: Maybe<BankDetailConnection>;
  byCustomerFormPublicIdSource?: Maybe<Source>;
  byPublicIdSource?: Maybe<Source>;
  companies?: Maybe<CompanyConnection>;
  company?: Maybe<Company>;
  contact?: Maybe<Contact>;
  contactInfo?: Maybe<ContactInfo>;
  contactInfos?: Maybe<ContactInfoConnection>;
  contacts?: Maybe<ContactConnection>;
  customerCompanies?: Maybe<CustomerCompanyConnection>;
  customerCompany?: Maybe<CustomerCompany>;
  deal?: Maybe<Deal>;
  dealSupplies?: Maybe<DealSupplyConnection>;
  dealSupply?: Maybe<DealSupply>;
  deals?: Maybe<DealConnection>;
  document?: Maybe<Document>;
  documentType?: Maybe<DocumentType>;
  documentTypes?: Maybe<DocumentTypeConnection>;
  documents?: Maybe<DocumentConnection>;
  edoType?: Maybe<EdoType>;
  edoTypes?: Maybe<EdoTypeConnection>;
  fedResourceCategories?: Maybe<FedResourceCategoryConnection>;
  fedResourceCategory?: Maybe<FedResourceCategory>;
  fsspContactInfo?: Maybe<FsspContactInfo>;
  fsspContactInfos?: Maybe<FsspContactInfoConnection>;
  guarantor?: Maybe<Guarantor>;
  guarantorCompanies?: Maybe<GuarantorCompanyConnection>;
  guarantorCompany?: Maybe<GuarantorCompany>;
  guarantors?: Maybe<GuarantorConnection>;
  leasingSubjectCategories?: Maybe<LeasingSubjectCategoryConnection>;
  leasingSubjectCategory?: Maybe<LeasingSubjectCategory>;
  leasingSubjectCategoryGroup?: Maybe<LeasingSubjectCategoryGroup>;
  leasingSubjectCategoryGroups?: Maybe<LeasingSubjectCategoryGroupConnection>;
  node?: Maybe<Node>;
  reconciliation?: Maybe<Reconciliation>;
  reconciliations?: Maybe<ReconciliationConnection>;
  source?: Maybe<Source>;
  sources?: Maybe<SourceConnection>;
  supplierCompanies?: Maybe<SupplierCompanyConnection>;
  supplierCompany?: Maybe<SupplierCompany>;
  user?: Maybe<User>;
  users?: Maybe<UserConnection>;
};


export type QueryAgentCommissionRewardArgs = {
  id: Scalars['ID'];
};


export type QueryAgentCommissionRewardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deal?: InputMaybe<Scalars['String']>;
  deal_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryApplicationArgs = {
  id: Scalars['ID'];
};


export type QueryApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<ApplicationFilter_Created>>>;
  customerCompany_inn?: InputMaybe<Scalars['String']>;
  customerCompany_name?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  source_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryBankDetailArgs = {
  id: Scalars['ID'];
};


export type QueryBankDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryByCustomerFormPublicIdSourceArgs = {
  customerFormPublicId: Scalars['Uuid'];
};


export type QueryByPublicIdSourceArgs = {
  publicId: Scalars['Uuid'];
};


export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inn?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryContactInfoArgs = {
  id: Scalars['ID'];
};


export type QueryContactInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<ContactFilter_Created>>>;
  email?: InputMaybe<Scalars['String']>;
  fio?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ContactFilter_Order>>>;
  phone?: InputMaybe<Scalars['String']>;
  verificationStatus?: InputMaybe<Scalars['String']>;
};


export type QueryCustomerCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<CustomerCompanyFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  inn?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryCustomerCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryDealArgs = {
  id: Scalars['ID'];
};


export type QueryDealSuppliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDealSupplyArgs = {
  id: Scalars['ID'];
};


export type QueryDealsArgs = {
  after?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Array<InputMaybe<DealFilter_Amount>>>;
  application_source_id?: InputMaybe<Scalars['Int']>;
  application_source_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<DealFilter_Created>>>;
  customerCompany_inn?: InputMaybe<Scalars['String']>;
  customerCompany_name?: InputMaybe<Scalars['String']>;
  dealSupplies_supplierCompany_id?: InputMaybe<Scalars['Int']>;
  dealSupplies_supplierCompany_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  order?: InputMaybe<Array<InputMaybe<DealFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supplierCompanies?: InputMaybe<Scalars['String']>;
  supplierCompanies_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supplyingStatus?: InputMaybe<Scalars['String']>;
  supplyingStatus_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user?: InputMaybe<Scalars['String']>;
  user_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentTypeArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryEdoTypeArgs = {
  id: Scalars['ID'];
};


export type QueryEdoTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EdoTypeFilter_Order>>>;
};


export type QueryFedResourceCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryFedResourceCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryFsspContactInfoArgs = {
  id: Scalars['ID'];
};


export type QueryFsspContactInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  contact_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  created?: InputMaybe<Array<InputMaybe<FsspContactInfoFilter_Created>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGuarantorArgs = {
  id: Scalars['ID'];
};


export type QueryGuarantorCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryGuarantorCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryGuarantorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLeasingSubjectCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLeasingSubjectCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryLeasingSubjectCategoryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryLeasingSubjectCategoryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryReconciliationArgs = {
  id: Scalars['ID'];
};


export type QueryReconciliationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  application_id?: InputMaybe<Scalars['Int']>;
  application_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  createdBy_id?: InputMaybe<Scalars['Int']>;
  createdBy_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  isActual?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ReconciliationFilter_Order>>>;
};


export type QuerySourceArgs = {
  id: Scalars['ID'];
};


export type QuerySourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  bdmUser_id?: InputMaybe<Scalars['Int']>;
  bdmUser_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  category_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  company_inn?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  user_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySupplierCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inn?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
};


export type QuerySupplierCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<UserFilter_Order>>>;
  roles?: InputMaybe<Scalars['String']>;
};

export type Reconciliation = Node & {
  __typename?: 'Reconciliation';
  _id: Scalars['Int'];
  advanceRate: Scalars['Float'];
  ageType?: Maybe<AgeType>;
  amount: Scalars['RoublePenniesAmmount'];
  application: Application;
  comissionRate: Scalars['Float'];
  companies?: Maybe<CompanyConnection>;
  contacts?: Maybe<ContactConnection>;
  created: Scalars['Iso8601DateTime'];
  createdBy: User;
  customerStatus: ReconciliationCustomerStatus;
  durationMonths: Scalars['Int'];
  feraStatus: ReconciliationFeraStatus;
  id: Scalars['ID'];
  initiatedBy: ReconciliationInitiatedBy;
  insuranceRate: Scalars['Float'];
  interestRate: Scalars['Float'];
  isActual: Scalars['Boolean'];
  kind: Kind;
  liquidityType?: Maybe<LiquidityType>;
  monthPayment?: Maybe<Scalars['RoublePenniesAmmount']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  vatRate: Scalars['Float'];
};


export type ReconciliationCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inn?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type ReconciliationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<ContactFilter_Created>>>;
  email?: InputMaybe<Scalars['String']>;
  fio?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ContactFilter_Order>>>;
  phone?: InputMaybe<Scalars['String']>;
  verificationStatus?: InputMaybe<Scalars['String']>;
};

/** Connection for Reconciliation. */
export type ReconciliationConnection = {
  __typename?: 'ReconciliationConnection';
  edges?: Maybe<Array<Maybe<ReconciliationEdge>>>;
  pageInfo: ReconciliationPageInfo;
  totalCount: Scalars['Int'];
};

/** Customer status of reconciliation */
export enum ReconciliationCustomerStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** Edge of Reconciliation. */
export type ReconciliationEdge = {
  __typename?: 'ReconciliationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Reconciliation>;
};

/** Fera status of reconciliation */
export enum ReconciliationFeraStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type ReconciliationFilter_Order = {
  created?: InputMaybe<Scalars['String']>;
};

/** Initiated by type */
export enum ReconciliationInitiatedBy {
  Customer = 'customer',
  Fera = 'fera'
}

/** Information about the current page. */
export type ReconciliationPageInfo = {
  __typename?: 'ReconciliationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** ReportingBkiGuarantorContractStatus */
export enum ReportingBkiGuarantorContractStatus {
  Pending = 'pending',
  Sent = 'sent'
}

/** ReportingBkiLeasingContractStatus */
export enum ReportingBkiLeasingContractStatus {
  Pending = 'pending',
  Sent = 'sent'
}

/** ReportingFedresStatus */
export enum ReportingFedresStatus {
  Pending = 'pending',
  Sent = 'sent'
}

/** ReportingRfmStatus */
export enum ReportingRfmStatus {
  NotRequired = 'not_required',
  PartlySent = 'partly_sent',
  Pending = 'pending',
  Sent = 'sent'
}

export type Source = Node & {
  __typename?: 'Source';
  _id: Scalars['Int'];
  apiToken?: Maybe<Scalars['String']>;
  applications?: Maybe<ApplicationConnection>;
  bdmUser?: Maybe<User>;
  category?: Maybe<SourceCategory>;
  commissionRewardRate?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  contactInfos?: Maybe<ContactInfoConnection>;
  created: Scalars['Iso8601DateTime'];
  customerFormPublicId?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentConnection>;
  edoCustomValue?: Maybe<Scalars['String']>;
  edoStatus?: Maybe<Scalars['Boolean']>;
  edoTypes?: Maybe<EdoTypeConnection>;
  id: Scalars['ID'];
  leasingSubjectCategories?: Maybe<LeasingSubjectCategoryConnection>;
  name: Scalars['String'];
  publicId?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  user?: Maybe<User>;
  widgetPublicId?: Maybe<Scalars['String']>;
};


export type SourceApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Array<InputMaybe<ApplicationFilter_Created>>>;
  customerCompany_inn?: InputMaybe<Scalars['String']>;
  customerCompany_name?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leasingSubjectCategories?: InputMaybe<Scalars['String']>;
  leasingSubjectCategories_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  source_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type SourceContactInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type SourceDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type SourceEdoTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EdoTypeFilter_Order>>>;
};


export type SourceLeasingSubjectCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Source Category */
export enum SourceCategory {
  Agent = 'agent',
  Fera = 'fera',
  Marketplace = 'marketplace',
  Supplier = 'supplier'
}

/** Connection for Source. */
export type SourceConnection = {
  __typename?: 'SourceConnection';
  edges?: Maybe<Array<Maybe<SourceEdge>>>;
  pageInfo: SourcePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Source. */
export type SourceEdge = {
  __typename?: 'SourceEdge';
  cursor: Scalars['String'];
  node?: Maybe<Source>;
};

/** Information about the current page. */
export type SourcePageInfo = {
  __typename?: 'SourcePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type SupplierCompany = Node & {
  __typename?: 'SupplierCompany';
  _id: Scalars['Int'];
  accreditationComment?: Maybe<Scalars['String']>;
  accreditationCompletedAt?: Maybe<Scalars['Iso8601DateTime']>;
  accreditationCompletedBy?: Maybe<User>;
  accreditationStatus?: Maybe<AccreditationStatus>;
  bankDetails?: Maybe<BankDetailConnection>;
  created: Scalars['Iso8601DateTime'];
  dadata?: Maybe<Scalars['Iterable']>;
  documents?: Maybe<DocumentConnection>;
  edoCustomValue?: Maybe<Scalars['String']>;
  edoStatus?: Maybe<Scalars['Boolean']>;
  edoTypes?: Maybe<EdoTypeConnection>;
  id: Scalars['ID'];
  inn: Scalars['String'];
  kpp?: Maybe<Scalars['String']>;
  lastDadataRequestDate?: Maybe<Scalars['Iso8601DateTime']>;
  lastDadataRequestUser?: Maybe<User>;
  leasingSubjectCategories?: Maybe<LeasingSubjectCategoryConnection>;
  name?: Maybe<Scalars['String']>;
  needsUpdate: Scalars['Boolean'];
  okpo?: Maybe<Scalars['String']>;
  opf?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['Iso8601DateTime']>;
  updated?: Maybe<Scalars['Iso8601DateTime']>;
  vatKind?: Maybe<VatKind>;
};


export type SupplierCompanyBankDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type SupplierCompanyDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DocumentFilterByEntityType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type SupplierCompanyEdoTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EdoTypeFilter_Order>>>;
};


export type SupplierCompanyLeasingSubjectCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Connection for SupplierCompany. */
export type SupplierCompanyConnection = {
  __typename?: 'SupplierCompanyConnection';
  edges?: Maybe<Array<Maybe<SupplierCompanyEdge>>>;
  pageInfo: SupplierCompanyPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of SupplierCompany. */
export type SupplierCompanyEdge = {
  __typename?: 'SupplierCompanyEdge';
  cursor: Scalars['String'];
  node?: Maybe<SupplierCompany>;
};

/** Information about the current page. */
export type SupplierCompanyPageInfo = {
  __typename?: 'SupplierCompanyPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Supplying status */
export enum SupplyingStatus {
  Partially = 'partially',
  Shipped = 'shipped',
  Waiting = 'waiting'
}

export type User = Node & {
  __typename?: 'User';
  _id: Scalars['Int'];
  created: Scalars['Iso8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  roles: Array<Maybe<UserRoles>>;
  surname: Scalars['String'];
  updated?: Maybe<Scalars['Iso8601DateTime']>;
};

/** Connection for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: UserPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of User. */
export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node?: Maybe<User>;
};

export type UserFilter_Order = {
  name?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type UserPageInfo = {
  __typename?: 'UserPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum UserRoles {
  RoleAdmin = 'ROLE_ADMIN',
  RoleBdm = 'ROLE_BDM',
  RoleManager = 'ROLE_MANAGER',
  RoleRisks = 'ROLE_RISKS',
  RoleUser = 'ROLE_USER'
}

/** VAT kind */
export enum VatKind {
  Medicine = 'medicine',
  Osno = 'osno',
  Usn = 'usn'
}

export type AddAgentCommissionRewardInput = {
  /** Amount in pennies */
  amount: Scalars['RoublePenniesAmmount'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Commission rate percentage (regex /^\d{1,6}.?\d{0,8}$/) */
  commissionRate: Scalars['Float'];
  /** Deal ID */
  deal: Scalars['ID'];
  /** Source ID */
  source: Scalars['ID'];
};

export type AddAgentCommissionRewardPayload = {
  __typename?: 'addAgentCommissionRewardPayload';
  agentCommissionReward?: Maybe<AgentCommissionReward>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddApplicationInput = {
  /** Advance rate percentage (regex /^\d{1,6}\.?\d{0,8}$/) */
  advanceRate?: InputMaybe<Scalars['Float']>;
  /** Amount in pennies */
  amount?: InputMaybe<Scalars['RoublePenniesAmmount']>;
  /** Channel */
  channel?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Comment */
  comment?: InputMaybe<Scalars['String']>;
  /** Contacts */
  contacts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Duration (months, minimum 6) */
  durationMonths?: InputMaybe<Scalars['Int']>;
  /** Client email */
  email?: InputMaybe<Scalars['String']>;
  /** Client full name */
  fio: Scalars['String'];
  /** Client company INN */
  inn: Scalars['String'];
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Manager data */
  manager?: InputMaybe<ApplicationManagerDataType>;
  /** Client phone number */
  phone: Scalars['String'];
  /** Source ID */
  source?: InputMaybe<Scalars['ID']>;
  /** Source customer form public ID */
  sourceCustomerFormPublicId?: InputMaybe<Scalars['Uuid']>;
  /** Additional source data as json encoded string */
  sourceDataJson?: InputMaybe<Scalars['String']>;
  /** Source public ID */
  sourcePublicId?: InputMaybe<Scalars['Uuid']>;
  /** Source widget public ID */
  sourceWidgetPublicId?: InputMaybe<Scalars['Uuid']>;
  /** Utm source */
  utmSource?: InputMaybe<Scalars['String']>;
};

export type AddApplicationPayload = {
  __typename?: 'addApplicationPayload';
  application?: Maybe<Application>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddBankDetailInput = {
  /** Bank account number */
  account: Scalars['String'];
  /** Bank BIC */
  bic: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Correspondent account number */
  correspondentAccount: Scalars['String'];
  /** Customer Company ID */
  customerCompany?: InputMaybe<Scalars['ID']>;
  /** Bank name */
  name: Scalars['String'];
  /** Supplier Company ID */
  supplierCompany?: InputMaybe<Scalars['ID']>;
};

export type AddBankDetailPayload = {
  __typename?: 'addBankDetailPayload';
  bankDetail?: Maybe<BankDetail>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Company INN */
  inn: Scalars['String'];
};

export type AddCompanyPayload = {
  __typename?: 'addCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type AddContactInfoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact info comment */
  comment?: InputMaybe<Scalars['String']>;
  /** Contact info email */
  email?: InputMaybe<Scalars['String']>;
  /** Contact info full name */
  fio: Scalars['String'];
  /** Contact info phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Source ID */
  source?: InputMaybe<Scalars['ID']>;
  /** SupplierCompany ID */
  supplierCompany?: InputMaybe<Scalars['ID']>;
};

export type AddContactInfoPayload = {
  __typename?: 'addContactInfoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<ContactInfo>;
};

export type AddContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact email */
  email?: InputMaybe<Scalars['String']>;
  /** Contact full name */
  fio: Scalars['String'];
  /** Contact phone number */
  phone?: InputMaybe<Scalars['String']>;
};

export type AddContactPayload = {
  __typename?: 'addContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
};

export type AddContactToApplicationInput = {
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contact: Scalars['ID'];
};

export type AddContactToApplicationPayload = {
  __typename?: 'addContactToApplicationPayload';
  application?: Maybe<Application>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddDealInput = {
  /** Sign date, ISO-8601 */
  advancePaymentDate?: InputMaybe<Scalars['Iso8601DateTime']>;
  /** Advance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  advanceRate: Scalars['Float'];
  /** Amount in pennies */
  amount: Scalars['RoublePenniesAmmount'];
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Comission rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  comissionRate: Scalars['Float'];
  /** Contract date, ISO-8601 */
  contractDate?: InputMaybe<Scalars['Iso8601DateTime']>;
  /** Custromer Company ID */
  customerCompany: Scalars['ID'];
  /** Duration (months, minimum 6) */
  durationMonths: Scalars['Int'];
  /** Deal insurance kind */
  insuranceKind?: InputMaybe<DealInsuranceKind>;
  /** Insurance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  insuranceRate: Scalars['Float'];
  /** Interest rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  interestRate: Scalars['Float'];
  /** Deal kind */
  kind?: InputMaybe<Kind>;
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Sign date, ISO-8601 */
  signDate?: InputMaybe<Scalars['Iso8601DateTime']>;
  /** Supplying status */
  supplyingStatus?: InputMaybe<SupplyingStatus>;
  /** Responsible manager Id */
  user?: InputMaybe<Scalars['ID']>;
  /** Vat rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  vatRate: Scalars['Float'];
};

export type AddDealPayload = {
  __typename?: 'addDealPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deal?: Maybe<Deal>;
};

export type AddDealSupplyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deal ID */
  deal: Scalars['ID'];
  /** Deal supply mode */
  mode: DealSupplyMode;
  /** SupplierCompany ID */
  supplierCompany: Scalars['ID'];
};

export type AddDealSupplyPayload = {
  __typename?: 'addDealSupplyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dealSupply?: Maybe<DealSupply>;
};

export type AddDocumentTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Entity */
  entity?: InputMaybe<DocumentTypeEntity>;
  /** Type */
  type: Scalars['String'];
};

export type AddDocumentTypePayload = {
  __typename?: 'addDocumentTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentType?: Maybe<DocumentType>;
};

export type AddFsspContactInfoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contact: Scalars['ID'];
};

export type AddFsspContactInfoPayload = {
  __typename?: 'addFsspContactInfoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fsspContactInfo?: Maybe<FsspContactInfo>;
};

export type AddGuarantorCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Guarantor company INN */
  inn: Scalars['String'];
};

export type AddGuarantorCompanyPayload = {
  __typename?: 'addGuarantorCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  guarantorCompany?: Maybe<GuarantorCompany>;
};

export type AddGuarantorInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contactId?: InputMaybe<Scalars['ID']>;
  /** Deal ID */
  dealId: Scalars['ID'];
  /** Guarantor company ID */
  guarantorCompanyId?: InputMaybe<Scalars['ID']>;
  /** Sign method */
  signMethod?: InputMaybe<GuarantorSignMethod>;
  /** Status */
  status: GuarantorStatus;
};

export type AddGuarantorPayload = {
  __typename?: 'addGuarantorPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  guarantor?: Maybe<Guarantor>;
};

export type AddGuarantorToDealInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deal ID */
  deal: Scalars['ID'];
  /** Guarantor ID */
  guarantor: Scalars['ID'];
};

export type AddGuarantorToDealPayload = {
  __typename?: 'addGuarantorToDealPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deal?: Maybe<Deal>;
};

export type AddLeasingSubjectCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Leasing subject category name */
  name: Scalars['String'];
};

export type AddLeasingSubjectCategoryPayload = {
  __typename?: 'addLeasingSubjectCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  leasingSubjectCategory?: Maybe<LeasingSubjectCategory>;
};

export type AddPartnershipApplicationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Partner company name */
  companyName: Scalars['String'];
  /** Partner email */
  email?: InputMaybe<Scalars['String']>;
  /** Partner full name */
  fio: Scalars['String'];
  /** Partner company INN */
  inn: Scalars['String'];
  /** Partner phone number */
  phone: Scalars['String'];
  /** Token for yandex captcha */
  smartToken: Scalars['String'];
};

export type AddPartnershipApplicationPayload = {
  __typename?: 'addPartnershipApplicationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  partnershipApplication?: Maybe<PartnershipApplication>;
};

export type AddReconciliationInput = {
  /** Advance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  advanceRate: Scalars['Float'];
  /** Age type */
  ageType: AgeType;
  /** Amount in pennies */
  amount: Scalars['RoublePenniesAmmount'];
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Comission rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  comissionRate: Scalars['Float'];
  /** Guarantor companies(inn) */
  companyInns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Guarantor contacts */
  contacts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Customer status */
  customerStatus?: InputMaybe<ReconciliationCustomerStatus>;
  /** Duration (months, minimum 6) */
  durationMonths: Scalars['Int'];
  /** Fera status */
  feraStatus?: InputMaybe<ReconciliationFeraStatus>;
  /** By whom reconciliation is initiated */
  initiatedBy?: InputMaybe<ReconciliationInitiatedBy>;
  /** Insurance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  insuranceRate: Scalars['Float'];
  /** Interest rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  interestRate: Scalars['Float'];
  /** Reconsecration actuality */
  isActual?: InputMaybe<Scalars['Boolean']>;
  /** Deal kind */
  kind?: InputMaybe<Kind>;
  /** Liquidity type */
  liquidityType: LiquidityType;
  /** Amount in pennies */
  monthPayment?: InputMaybe<Scalars['RoublePenniesAmmount']>;
  /** Vat rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  vatRate: Scalars['Float'];
};

export type AddReconciliationPayload = {
  __typename?: 'addReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type AddSourceInput = {
  /** Development manager Id */
  bdmUser?: InputMaybe<Scalars['ID']>;
  /** Source category */
  category?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Source company */
  company?: InputMaybe<Scalars['ID']>;
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Source name */
  name: Scalars['String'];
  /** Responsible manager Id */
  user?: InputMaybe<Scalars['ID']>;
};

export type AddSourcePayload = {
  __typename?: 'addSourcePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
};

export type AddSupplierCompanyInput = {
  /** Accreditation comment */
  accreditationComment?: InputMaybe<Scalars['String']>;
  /** Accreditation status */
  accreditationStatus?: InputMaybe<AccreditationStatus>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Supplier company INN */
  inn: Scalars['String'];
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Vat kind */
  vatKind?: InputMaybe<VatKind>;
};

export type AddSupplierCompanyPayload = {
  __typename?: 'addSupplierCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierCompany?: Maybe<SupplierCompany>;
};

export type AddWithCompanyGuarantorInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deal ID */
  dealId: Scalars['ID'];
  /** Guarantor company INN */
  guarantorCompanyInn: Scalars['ID'];
  /** Sign method */
  signMethod?: InputMaybe<GuarantorSignMethod>;
  /** Status */
  status: GuarantorStatus;
};

export type AddWithCompanyGuarantorPayload = {
  __typename?: 'addWithCompanyGuarantorPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  guarantor?: Maybe<Guarantor>;
};

export type ClearTokenSourceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Source ID */
  id: Scalars['ID'];
};

export type ClearTokenSourcePayload = {
  __typename?: 'clearTokenSourcePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
};

export type LoginUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** User email */
  login: Scalars['String'];
  /** User password */
  password: Scalars['String'];
};

export type LoginUserPayload = {
  __typename?: 'loginUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<LoginUserPayloadData>;
};

export type LoginUserPayloadData = Node & {
  __typename?: 'loginUserPayloadData';
  id: Scalars['ID'];
  jwt: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RefreshFromDadataCustomerCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Customer company INN */
  inn: Scalars['String'];
};

export type RefreshFromDadataCustomerCompanyPayload = {
  __typename?: 'refreshFromDadataCustomerCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerCompany?: Maybe<CustomerCompany>;
};

export type RefreshFromDadataGuarantorCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Guarantor company INN */
  inn: Scalars['String'];
};

export type RefreshFromDadataGuarantorCompanyPayload = {
  __typename?: 'refreshFromDadataGuarantorCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  guarantorCompany?: Maybe<GuarantorCompany>;
};

export type RefreshFromDadataSupplierCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Supplier company INN */
  inn: Scalars['String'];
};

export type RefreshFromDadataSupplierCompanyPayload = {
  __typename?: 'refreshFromDadataSupplierCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierCompany?: Maybe<SupplierCompany>;
};

export type RefreshFsspContactInfoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** FsspContactInfo ID */
  fsspContactInfo: Scalars['ID'];
};

export type RefreshFsspContactInfoPayload = {
  __typename?: 'refreshFsspContactInfoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fsspContactInfo?: Maybe<FsspContactInfo>;
};

export type RefreshTokenUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Refresh token */
  refresh_token: Scalars['String'];
};

export type RefreshTokenUserPayload = {
  __typename?: 'refreshTokenUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<RefreshTokenUserPayloadData>;
};

export type RefreshTokenUserPayloadData = Node & {
  __typename?: 'refreshTokenUserPayloadData';
  id: Scalars['ID'];
  jwt: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RegenerateTokenSourceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Source ID */
  id: Scalars['ID'];
};

export type RegenerateTokenSourcePayload = {
  __typename?: 'regenerateTokenSourcePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
};

export type RemoveAgentCommissionRewardInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Agent commission reward ID */
  id: Scalars['ID'];
};

export type RemoveAgentCommissionRewardPayload = {
  __typename?: 'removeAgentCommissionRewardPayload';
  agentCommissionReward?: Maybe<AgentCommissionReward>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveApplicationInput = {
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type RemoveApplicationPayload = {
  __typename?: 'removeApplicationPayload';
  application?: Maybe<Application>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveBankDetailInput = {
  /** Bank Detail ID */
  bankDetail: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type RemoveBankDetailPayload = {
  __typename?: 'removeBankDetailPayload';
  bankDetail?: Maybe<BankDetail>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveCompanyFromReconciliationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Company ID */
  company: Scalars['ID'];
  /** Reconciliation ID */
  reconciliation: Scalars['ID'];
};

export type RemoveCompanyFromReconciliationPayload = {
  __typename?: 'removeCompanyFromReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type RemoveContactFromApplicationInput = {
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contact: Scalars['ID'];
};

export type RemoveContactFromApplicationPayload = {
  __typename?: 'removeContactFromApplicationPayload';
  application?: Maybe<Application>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveContactFromReconciliationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contact: Scalars['ID'];
  /** Reconciliation ID */
  reconciliation: Scalars['ID'];
};

export type RemoveContactFromReconciliationPayload = {
  __typename?: 'removeContactFromReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type RemoveContactInfoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact info ID */
  contactInfo: Scalars['ID'];
};

export type RemoveContactInfoPayload = {
  __typename?: 'removeContactInfoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<ContactInfo>;
};

export type RemoveContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contact: Scalars['ID'];
};

export type RemoveContactPayload = {
  __typename?: 'removeContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
};

export type RemoveDealSupplyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** DealSupply ID */
  id: Scalars['ID'];
};

export type RemoveDealSupplyPayload = {
  __typename?: 'removeDealSupplyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dealSupply?: Maybe<DealSupply>;
};

export type RemoveDocumentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Document ID */
  document: Scalars['ID'];
};

export type RemoveDocumentPayload = {
  __typename?: 'removeDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
};

export type RemoveDocumentTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Document type ID */
  documentType: Scalars['ID'];
};

export type RemoveDocumentTypePayload = {
  __typename?: 'removeDocumentTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentType?: Maybe<DocumentType>;
};

export type RemoveGuarantorInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Guarantor ID */
  guarantor: Scalars['ID'];
};

export type RemoveGuarantorPayload = {
  __typename?: 'removeGuarantorPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  guarantor?: Maybe<Guarantor>;
};

export type RemoveLeasingSubjectCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Leasing subject category ID */
  id: Scalars['ID'];
};

export type RemoveLeasingSubjectCategoryPayload = {
  __typename?: 'removeLeasingSubjectCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  leasingSubjectCategory?: Maybe<LeasingSubjectCategory>;
};

export type RemoveReconciliationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Reconciliation ID */
  reconciliation: Scalars['ID'];
};

export type RemoveReconciliationPayload = {
  __typename?: 'removeReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type RemoveSourceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Source ID */
  source: Scalars['ID'];
};

export type RemoveSourcePayload = {
  __typename?: 'removeSourcePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
};

export type UpdateAgentCommissionRewardInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Agent commission reward ID */
  id: Scalars['ID'];
  /** Status */
  status?: InputMaybe<AgentCommissionRewardStatus>;
};

export type UpdateAgentCommissionRewardPayload = {
  __typename?: 'updateAgentCommissionRewardPayload';
  agentCommissionReward?: Maybe<AgentCommissionReward>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateApplicationInput = {
  /** Channel */
  channel?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Comment */
  comment?: InputMaybe<Scalars['String']>;
  /** Application ID */
  id: Scalars['ID'];
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Closed application loss reason */
  lossReason?: InputMaybe<Scalars['ID']>;
  /** Reconciliations status */
  reconciliationsStatus?: InputMaybe<ApplicationReconciliationsStatus>;
  /** Source ID */
  source?: InputMaybe<Scalars['ID']>;
  /** Application status */
  status?: InputMaybe<Scalars['String']>;
  /** Responsible manager Id */
  user?: InputMaybe<Scalars['ID']>;
};

export type UpdateApplicationPayload = {
  __typename?: 'updateApplicationPayload';
  application?: Maybe<Application>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBankDetailInput = {
  /** Bank account number */
  account?: InputMaybe<Scalars['String']>;
  /** Bank BIC */
  bic?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Correspondent account number */
  correspondentAccount?: InputMaybe<Scalars['String']>;
  /** Customer Company ID */
  customerCompany?: InputMaybe<Scalars['ID']>;
  /** Bank Detail ID */
  id: Scalars['ID'];
  /** Bank name */
  name?: InputMaybe<Scalars['String']>;
  /** Supplier Company ID */
  supplierCompany?: InputMaybe<Scalars['ID']>;
};

export type UpdateBankDetailPayload = {
  __typename?: 'updateBankDetailPayload';
  bankDetail?: Maybe<BankDetail>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  id: Scalars['ID'];
  /** Company KPP */
  kpp?: InputMaybe<Scalars['String']>;
  /** Company name */
  name?: InputMaybe<Scalars['String']>;
  /** Company OKPO */
  okpo?: InputMaybe<Scalars['String']>;
  /** Company OPF */
  opf?: InputMaybe<Scalars['String']>;
  /** Company registration date, ISO-8601 */
  registrationDate?: InputMaybe<Scalars['Iso8601DateTime']>;
};

export type UpdateCompanyPayload = {
  __typename?: 'updateCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type UpdateContactInfoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact info comment */
  comment?: InputMaybe<Scalars['String']>;
  /** Contact info email */
  email?: InputMaybe<Scalars['String']>;
  /** Contact info full name */
  fio?: InputMaybe<Scalars['String']>;
  /** Contact info ID */
  id: Scalars['ID'];
  /** Contact info phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Source ID */
  source?: InputMaybe<Scalars['ID']>;
  /** SupplierCompany ID */
  supplierCompany?: InputMaybe<Scalars['ID']>;
};

export type UpdateContactInfoPayload = {
  __typename?: 'updateContactInfoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<ContactInfo>;
};

export type UpdateContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact email */
  email?: InputMaybe<Scalars['String']>;
  /** Contact full name */
  fio?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  id: Scalars['ID'];
  /** Contact phone number */
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateContactPayload = {
  __typename?: 'updateContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
};

export type UpdateCustomerCompanyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Supplier company edoCustomValue */
  edoCustomValue?: InputMaybe<Scalars['String']>;
  /** Does it have EDO or not or we don't know yet */
  edoStatus?: InputMaybe<Scalars['Boolean']>;
  /** Edo types */
  edoTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Contact ID */
  id: Scalars['ID'];
};

export type UpdateCustomerCompanyPayload = {
  __typename?: 'updateCustomerCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerCompany?: Maybe<CustomerCompany>;
};

export type UpdateDealInput = {
  /** Sign date, ISO-8601 */
  advancePaymentDate?: InputMaybe<Scalars['Iso8601DateTime']>;
  /** Advance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  advanceRate?: InputMaybe<Scalars['Float']>;
  /** Amount in pennies */
  amount?: InputMaybe<Scalars['RoublePenniesAmmount']>;
  /** Application ID */
  application?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Comission rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  comissionRate?: InputMaybe<Scalars['Float']>;
  /** Contract date, ISO-8601 */
  contractDate?: InputMaybe<Scalars['Iso8601DateTime']>;
  /** Custromer Company ID */
  customerCompany?: InputMaybe<Scalars['ID']>;
  /** Duration (months, minimum 6) */
  durationMonths?: InputMaybe<Scalars['Int']>;
  /** Deal ID */
  id: Scalars['ID'];
  /** Insurance contract */
  insuranceContract?: InputMaybe<Scalars['String']>;
  /** Insurance contract date, ISO-8601 */
  insuranceContractDate?: InputMaybe<Scalars['Iso8601DateTime']>;
  /** Deal insurance kind */
  insuranceKind?: InputMaybe<DealInsuranceKind>;
  /** Insurance rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  insuranceRate?: InputMaybe<Scalars['Float']>;
  /** Interest rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  interestRate?: InputMaybe<Scalars['Float']>;
  /** Deal kind */
  kind?: InputMaybe<Kind>;
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Reporting BKI guarantor contract status */
  reportingBkiGuarantorContractStatus?: InputMaybe<ReportingBkiGuarantorContractStatus>;
  /** Reporting BKI leasing contract status */
  reportingBkiLeasingContractStatus?: InputMaybe<ReportingBkiLeasingContractStatus>;
  /** status of sent payments set */
  reportingBkiPaymentsSent?: InputMaybe<Array<InputMaybe<BkiPayments>>>;
  /** Reporting fedres status */
  reportingFedresStatus?: InputMaybe<ReportingFedresStatus>;
  /** Ros fin monitoring status */
  reportingRfmStatus?: InputMaybe<ReportingRfmStatus>;
  /** Sign date, ISO-8601 */
  signDate?: InputMaybe<Scalars['Iso8601DateTime']>;
  /** Status */
  status?: InputMaybe<DealStatus>;
  /** Supplying status */
  supplyingStatus?: InputMaybe<SupplyingStatus>;
  /** Responsible manager Id */
  user?: InputMaybe<Scalars['ID']>;
  /** Vat rate percentage (regex /^\d{1,6}\.?\d{0,4}$/) */
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type UpdateDealPayload = {
  __typename?: 'updateDealPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deal?: Maybe<Deal>;
};

export type UpdateDealSupplyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deal ID */
  deal?: InputMaybe<Scalars['ID']>;
  /** Frame contract document ID */
  frameContractDocument?: InputMaybe<Scalars['ID']>;
  /** DealSupply ID */
  id: Scalars['ID'];
  /** Deal supply mode */
  mode?: InputMaybe<DealSupplyMode>;
  /** Status */
  status?: InputMaybe<DealSupplyStatus>;
  /** SupplierCompany ID */
  supplierCompany?: InputMaybe<Scalars['ID']>;
};

export type UpdateDealSupplyPayload = {
  __typename?: 'updateDealSupplyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  dealSupply?: Maybe<DealSupply>;
};

export type UpdateGuarantorInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contactId?: InputMaybe<Scalars['ID']>;
  /** Deal ID */
  dealId?: InputMaybe<Scalars['ID']>;
  /** Guarantor company ID */
  guarantorCompanyId?: InputMaybe<Scalars['ID']>;
  /** Guarantor ID */
  id: Scalars['ID'];
  /** Sign method */
  signMethod?: InputMaybe<GuarantorSignMethod>;
  /** Status */
  status?: InputMaybe<GuarantorStatus>;
};

export type UpdateGuarantorPayload = {
  __typename?: 'updateGuarantorPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  guarantor?: Maybe<Guarantor>;
};

export type UpdateLeasingSubjectCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Leasing subject category ID */
  id: Scalars['ID'];
  /** Leasing subject category name */
  name: Scalars['String'];
};

export type UpdateLeasingSubjectCategoryPayload = {
  __typename?: 'updateLeasingSubjectCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  leasingSubjectCategory?: Maybe<LeasingSubjectCategory>;
};

export type UpdateReconciliationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Customer status */
  customerStatus?: InputMaybe<ReconciliationCustomerStatus>;
  /** Fera status */
  feraStatus?: InputMaybe<ReconciliationFeraStatus>;
  /** Reconciliation ID */
  id: Scalars['ID'];
  /** Reconciliation actuality */
  isActual?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateReconciliationPayload = {
  __typename?: 'updateReconciliationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<Reconciliation>;
};

export type UpdateSourceInput = {
  /** Development manager Id */
  bdmUser?: InputMaybe<Scalars['ID']>;
  /** Source category */
  category?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Commission reward rate (regex /^\d{1,6}\.?\d{0,8}$/) */
  commissionRewardRate?: InputMaybe<Scalars['Float']>;
  /** Source company */
  company?: InputMaybe<Scalars['ID']>;
  /** Source company edoCustomValue */
  edoCustomValue?: InputMaybe<Scalars['String']>;
  /** Does it have EDO or not or we don't know yet */
  edoStatus?: InputMaybe<Scalars['Boolean']>;
  /** Edo types */
  edoTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Source ID */
  id: Scalars['ID'];
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Source name */
  name?: InputMaybe<Scalars['String']>;
  /** Responsible manager Id */
  user?: InputMaybe<Scalars['ID']>;
};

export type UpdateSourcePayload = {
  __typename?: 'updateSourcePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
};

export type UpdateSupplierCompanyInput = {
  /** Accreditation comment */
  accreditationComment?: InputMaybe<Scalars['String']>;
  /** Accreditation status */
  accreditationStatus?: InputMaybe<AccreditationStatus>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Supplier company edoCustomValue */
  edoCustomValue?: InputMaybe<Scalars['String']>;
  /** Does it have EDO or not or we don't know yet */
  edoStatus?: InputMaybe<Scalars['Boolean']>;
  /** Edo types */
  edoTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Supplier company ID */
  id: Scalars['ID'];
  /** Leasing subject categories */
  leasingSubjectCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Vat kind */
  vatKind?: InputMaybe<VatKind>;
};

export type UpdateSupplierCompanyPayload = {
  __typename?: 'updateSupplierCompanyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierCompany?: Maybe<SupplierCompany>;
};

export type UploadCustomerFormApplicationDocumentInput = {
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The file to upload */
  file: Scalars['Upload'];
};

export type UploadCustomerFormApplicationDocumentPayload = {
  __typename?: 'uploadCustomerFormApplicationDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
};

export type UploadDocumentInput = {
  /** Agent Commission Reward ID */
  agentCommissionReward?: InputMaybe<Scalars['ID']>;
  /** Application ID */
  application?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Contact ID */
  contact?: InputMaybe<Scalars['ID']>;
  /** Customer Company ID */
  customerCompany?: InputMaybe<Scalars['ID']>;
  /** Deal ID */
  deal?: InputMaybe<Scalars['ID']>;
  /** Deal supply ID */
  dealSupply?: InputMaybe<Scalars['ID']>;
  /** The file to upload */
  file: Scalars['Upload'];
  /** Guarantor ID */
  guarantor?: InputMaybe<Scalars['ID']>;
  /** Source ID */
  source?: InputMaybe<Scalars['ID']>;
  /** Supplier Company ID */
  supplierCompany?: InputMaybe<Scalars['ID']>;
  /** Document Type ID */
  type: Scalars['ID'];
};

export type UploadDocumentPayload = {
  __typename?: 'uploadDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
};

export type UploadPartnerApplicationDocumentInput = {
  /** Application ID */
  application: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The file to upload */
  file: Scalars['Upload'];
};

export type UploadPartnerApplicationDocumentPayload = {
  __typename?: 'uploadPartnerApplicationDocumentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
};

export type AddApplicationMutationVariables = Exact<{
  input: AddApplicationInput;
}>;


export type AddApplicationMutation = { __typename?: 'Mutation', addApplication?: { __typename?: 'addApplicationPayload', clientMutationId?: string | null, application?: { __typename?: 'Application', id: string, _id: number } | null } | null };

export type AddPartnershipApplicationMutationVariables = Exact<{
  input: AddPartnershipApplicationInput;
}>;


export type AddPartnershipApplicationMutation = { __typename?: 'Mutation', addPartnershipApplication?: { __typename?: 'addPartnershipApplicationPayload', partnershipApplication?: { __typename?: 'PartnershipApplication', email?: string | null } | null } | null };

export type UploadCustomerFormDocumentMutationVariables = Exact<{
  input: UploadCustomerFormApplicationDocumentInput;
}>;


export type UploadCustomerFormDocumentMutation = { __typename?: 'Mutation', uploadCustomerFormApplicationDocument?: { __typename?: 'uploadCustomerFormApplicationDocumentPayload', document?: { __typename?: 'Document', _id: number } | null } | null };

export type UploadPartnerDocumentMutationVariables = Exact<{
  input: UploadPartnerApplicationDocumentInput;
}>;


export type UploadPartnerDocumentMutation = { __typename?: 'Mutation', uploadPartnerApplicationDocument?: { __typename?: 'uploadPartnerApplicationDocumentPayload', document?: { __typename?: 'Document', _id: number } | null } | null };

export type SourceByCustomerFormPublicIdQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type SourceByCustomerFormPublicIdQuery = { __typename?: 'Query', byCustomerFormPublicIdSource?: { __typename?: 'Source', customerFormPublicId?: string | null, name: string } | null };

export type SourceByPublicIdQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type SourceByPublicIdQuery = { __typename?: 'Query', byPublicIdSource?: { __typename?: 'Source', publicId?: string | null, customerFormPublicId?: string | null, name: string, user?: { __typename?: 'User', name: string, surname: string, email: string, phone: string } | null, bdmUser?: { __typename?: 'User', name: string, surname: string, email: string, phone: string } | null } | null };


export const AddApplicationDocument = gql`
    mutation addApplication($input: addApplicationInput!) {
  addApplication(input: $input) {
    application {
      id
      _id
    }
    clientMutationId
  }
}
    `;
export const AddPartnershipApplicationDocument = gql`
    mutation addPartnershipApplication($input: addPartnershipApplicationInput!) {
  addPartnershipApplication(input: $input) {
    partnershipApplication {
      email
    }
  }
}
    `;
export const UploadCustomerFormDocumentDocument = gql`
    mutation uploadCustomerFormDocument($input: uploadCustomerFormApplicationDocumentInput!) {
  uploadCustomerFormApplicationDocument(input: $input) {
    document {
      _id
    }
  }
}
    `;
export const UploadPartnerDocumentDocument = gql`
    mutation uploadPartnerDocument($input: uploadPartnerApplicationDocumentInput!) {
  uploadPartnerApplicationDocument(input: $input) {
    document {
      _id
    }
  }
}
    `;
export const SourceByCustomerFormPublicIdDocument = gql`
    query sourceByCustomerFormPublicId($id: Uuid!) {
  byCustomerFormPublicIdSource(customerFormPublicId: $id) {
    customerFormPublicId
    name
  }
}
    `;
export const SourceByPublicIdDocument = gql`
    query sourceByPublicId($id: Uuid!) {
  byPublicIdSource(publicId: $id) {
    publicId
    customerFormPublicId
    name
    user {
      name
      surname
      email
      phone
    }
    bdmUser {
      name
      surname
      email
      phone
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addApplication(variables: AddApplicationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddApplicationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddApplicationMutation>(AddApplicationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addApplication', 'mutation');
    },
    addPartnershipApplication(variables: AddPartnershipApplicationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddPartnershipApplicationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddPartnershipApplicationMutation>(AddPartnershipApplicationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addPartnershipApplication', 'mutation');
    },
    uploadCustomerFormDocument(variables: UploadCustomerFormDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadCustomerFormDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadCustomerFormDocumentMutation>(UploadCustomerFormDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uploadCustomerFormDocument', 'mutation');
    },
    uploadPartnerDocument(variables: UploadPartnerDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadPartnerDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadPartnerDocumentMutation>(UploadPartnerDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uploadPartnerDocument', 'mutation');
    },
    sourceByCustomerFormPublicId(variables: SourceByCustomerFormPublicIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SourceByCustomerFormPublicIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SourceByCustomerFormPublicIdQuery>(SourceByCustomerFormPublicIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sourceByCustomerFormPublicId', 'query');
    },
    sourceByPublicId(variables: SourceByPublicIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SourceByPublicIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SourceByPublicIdQuery>(SourceByPublicIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sourceByPublicId', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
