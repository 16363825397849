import { useState, useEffect } from 'react'
import type { CompanyData } from '../types/dadata'
import type DaDataSuggestions from '../types/dadata'
import { captureException } from '@sentry/nextjs'

const { NEXT_PUBLIC_DADATA_TOKEN } = process.env

const useCompanySuggestions = (query: string = ''): CompanyData[] | null => {
    const [suggestions, setSuggestions] = useState<CompanyData[] | null>([])

    useEffect(() => {
        if (query.length) {

            fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party', {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${NEXT_PUBLIC_DADATA_TOKEN}`
                },
                body: JSON.stringify({
                    query: query,
                    count: 5
                })
            })
                .then(res => res.json())
                .then((res: DaDataSuggestions) => {
                    setSuggestions(res.suggestions.map(suggestion => suggestion.data))
                })
                .catch((e) => {
                    // send error to sentry
                    setSuggestions(null)
                    captureException(e)
                })
        } else {
            setSuggestions([])
        }
    }, [query])

    return suggestions
}

export default useCompanySuggestions