import Api from '../graphql/client'
import ApiError from '../types/api'
import { useState } from 'react'

type ApiType = typeof Api

type ErrorObject = {
  message: string
  extensions: {
    category: string
    status: number
    violations: {
      path: string,
      message: string
    }[]
  }
}
type WithGraphqlErrors<T> = {
  data?: T
  errors?: ErrorObject[]
}

const useMutation = <T extends ValueOf<ApiType>>(mutation: T) => {
  type ReturnType = WithGraphqlErrors<AsyncReturnType<T>>

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError[]>()
  const [data, setData] = useState<ReturnType['data']>()

  const mutate = async (variables: Parameters<T>[0]): Promise<ReturnType | undefined> => {
    if (loading) return
    setLoading(true)
    let response: ReturnType = {}
    try {
      // @ts-ignore
      response.data = await mutation(variables)
      setData(response.data as ReturnType['data'])
    } catch (e: unknown) {
      //@ts-ignore
      setError(e?.response?.errors as ApiError[])
      //@ts-ignore
      response.errors = e?.response?.errors
    } finally {
      setLoading(false)
      //@ts-ignore
      return response as ReturnType
    }
  }

  return [mutate, { loading, error, data }] as const
}

export default useMutation
