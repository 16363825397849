import React, { FC, useRef, forwardRef, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import c from 'clsx'
import usePatternMask from '@src/hooks/usePatternMask'
import { IMaskInput, ReactMaskOpts } from 'react-imask'

export const PHONE_MASK = '+7 (000) 000-00-00'
export const NUMBER_MASK = 'num'
interface InputProps {
  label: string
  error?: string | FieldError
}

const defaultErrorTypeMessages: Record<string, string> = {
  required: 'Обязательное поле'
}

export const errorToString = (
  error?: string | FieldError
): string | undefined => {
  if (typeof error === 'string') {
    return error
  }
  if (error?.message) {
    return error.message
  }
  if (error?.type) {
    if (error.type in defaultErrorTypeMessages)
      return defaultErrorTypeMessages[error.type]
    return error.type
  }
}

const Input = forwardRef<
  HTMLInputElement,
  ComponentPropsWithoutRef<'input'> & InputProps
>(function Input({ label, children, error, ...props }, ref) {
  return (
    <div>
      <label
        className={c(
          'group block relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-full ring-1',
          !!error &&
            'bg-red-100 bg-opacity-5 ring-red-100 ring-opacity-40 ring-1'
        )}
      >
        <input
          className='inp pt-5 rounded-full pb-1 px-5 transition-opacity border-none outline-none focus:ring-0 focus:bg-opacity-0 w-full placeholder-grayscale-250'
          ref={ref}
          {...props}
        />
        <div className='inp-label transition-transform origin-top-left ease-in-out text-p350 text-grayscale-150 mx-5 mt-1 absolute transform translate-y-2 top-0 left-0 pointer-events-none'>
          {label}
        </div>
        {children}
      </label>
      {error && (
        <div className='text-red-150 text-p450 pl-2 pt-1'>
          {errorToString(error)}
        </div>
      )}
    </div>
  )
})

const BlockInput = forwardRef<
  HTMLInputElement,
  ComponentPropsWithoutRef<'input'> & InputProps
>(function Input({ label, children, error, ...props }, ref) {
  return (
    <div>
      <div className='inp-label text-p350 mb-2 flex'>
        {label}
        {props?.required && (
          <div className='w-[6px] h-[6px] rounded-full bg-red-150 ml-1' />
        )}
      </div>

      <label
        className={c(
          'group block relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-md ring-1',
          !!error && 'ring-red-100 ring-1'
        )}
      >
        <input
          className='py-3 px-4 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250'
          ref={ref}
          {...props}
        />
        {children}
      </label>
      {error && (
        <div className='text-red-150 text-p450 pt-1'>
          {errorToString(error)}
        </div>
      )}
    </div>
  )
})

const TextAreaInput = forwardRef<
  HTMLTextAreaElement,
  ComponentPropsWithoutRef<'textarea'> & InputProps
>(function Input({ label, children, error, ...props }, ref) {
  return (
    <div>
      <div className='inp-label text-p350 mb-2'>{label}</div>

      <label
        className={c(
          'group block relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-md ring-1',
          !!error && 'ring-red-100 ring-1'
        )}
      >
        <textarea
          className='py-3 px-4 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250'
          ref={ref}
          {...props}
        />
        {children}
      </label>
      {error && (
        <div className='text-red-150 text-p450 pt-1'>
          {errorToString(error)}
        </div>
      )}
    </div>
  )
})

interface MaskedInputProps {
  label: string
  mask: string
  error?: string | FieldError
}

const MaskedInput = forwardRef<
  HTMLInputElement,
  ComponentPropsWithoutRef<'input'> & MaskedInputProps
>(function Input({ label, children, error, mask, ...props }, refEl: any) {
  const inputMaskRef = useRef<HTMLInputElement | null>(null)

  const [, maskRef] = usePatternMask(inputMaskRef, {
    mask,
    blocks:
      mask === NUMBER_MASK
        ? {
            num: {
              mask: Number,
              thousandsSeparator: ' '
            }
          }
        : undefined
  })
  return (
    <BlockInput
      label={label}
      inputMode='text'
      type='text'
      error={error}
      ref={(el: HTMLInputElement) => {
        inputMaskRef.current = el
        refEl(el)
      }}
      {...props}
      onPaste={(e) => {
        if (mask === PHONE_MASK) {
          // gets all numbers from buffer
          const data =
            e.clipboardData.getData('text/plain').match(/\d+/g)?.join('') || ''
          // get start selection position
          const selectionStart = inputMaskRef.current?.selectionStart || 0
          if (
            (data.startsWith('8') || data.startsWith('7')) &&
            data.length > 10 &&
            selectionStart < 4
          ) {
            e.preventDefault()
            if (maskRef?.current) {
              maskRef.current.value = data.substring(1)
            }
          }
        }
        props?.onPaste?.(e)
      }}
    >
      {children}
    </BlockInput>
  )
})

interface MaskedNumberInputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  label?: string
  error?: string | FieldError
  value?: string
  onChange: (e: { target: { value: string } }) => void
}

const numberMaskOptions: ReactMaskOpts = {
  mask: Number,
  thousandsSeparator: ' ',
  scale: 2
}

/**
 * Компонент для ввода чисел с разделителем разрядов пробелами и разделителем дробной части запятой
 */
const MaskedNumberInput = forwardRef<HTMLInputElement, MaskedNumberInputProps>(
  function Input(props, ref) {
    const { label, children, error, onChange, value, placeholder, inputMode } =
      props
    return (
      <div>
        {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

        <label
          className={c(
            'group block relative bg-white-0 rounded-md',
            !!error && 'ring-red-100 ring-1'
          )}
        >
          <IMaskInput
            value={value}
            {...numberMaskOptions}
            inputRef={ref}
            placeholder={placeholder}
            inputMode={inputMode}
            className='px-4 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250 tabular-nums'
            onAccept={(v) => {
              onChange && onChange({ target: { value: v } })
            }}
          />
          {children}
        </label>
        {error && (
          <div className='text-red-150 text-p450 pl-4 pt-2'>
            {errorToString(error)}
          </div>
        )}
      </div>
    )
  }
)

interface MaskedPercentInputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  label?: string
  error?: string | FieldError
  value?: string
  onChange: (e: { target: { value: string } }) => void
}

const percentMaskOptions: ReactMaskOpts = {
  mask: 'num %',
  // adds percent to the end when not empty
  eager: true,
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      max: 100
    }
  }
}

/**
 * Компонент для ввода чисел с разделителем разрядов пробелами и разделителем дробной части запятой
 */
const MaskedPercentInput = forwardRef<
  HTMLInputElement,
  MaskedPercentInputProps
>(function Input(props, ref) {
  const {
    label,
    children,
    error,
    onChange,
    value,
    placeholder,
    inputMode,
    onBlur
  } = props
  return (
    <div className='w-[75px]'>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <label
        className={c(
          'group block relative bg-white-0 ',
          !!error && 'ring-red-100 ring-1'
        )}
      >
        <IMaskInput
          value={value}
          {...percentMaskOptions}
          inputRef={ref}
          placeholder={placeholder}
          inputMode={inputMode}
          className='transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250 tabular-nums'
          onAccept={(v) => {
            onChange && onChange({ target: { value: v } })
          }}
          onBlur={onBlur}
        />
        {children}
      </label>
      {error && (
        <div className='text-red-150 text-p450 pl-4 pt-2'>
          {errorToString(error)}
        </div>
      )}
    </div>
  )
})

export default Input
export {
  BlockInput,
  MaskedInput,
  TextAreaInput,
  MaskedNumberInput,
  MaskedPercentInput
}
