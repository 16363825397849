import IMask from 'imask'
import { useEffect, useRef, RefObject, MutableRefObject } from 'react'

interface PatternMaskCallbacks<Value, Opts extends IMask.MaskedPatternOptions = IMask.MaskedPatternOptions> {
  onAccept?: (value: Value, maskRef: IMask.InputMask<Opts>, e?: InputEvent) => void;
  onComplete?: (value: Value, maskRef: IMask.InputMask<Opts>, e?: InputEvent) => void;
}

export default function useIMask<
  Opts extends IMask.MaskedPatternOptions = IMask.MaskedPatternOptions,
  >(
    ref: RefObject<HTMLInputElement>,
    opts: Opts,
    callbacks: PatternMaskCallbacks<IMask.InputMask<Opts>['value'], Opts> = {},
): [ref: RefObject<HTMLInputElement>, mask: MutableRefObject<IMask.InputMask<Opts> | null>] {

  const maskRef = useRef<IMask.InputMask<Opts> | null>(null)
  const cbRefs = useRef<PatternMaskCallbacks<IMask.InputMask<Opts>['value'], Opts>>(callbacks)

  function _destroyMask() {
    if (maskRef.current) {
      maskRef.current.destroy()
      maskRef.current = null
    }
  }

  // lifecycle
  useEffect(() => {
    const el = ref.current
    if (!el || !opts?.mask) return _destroyMask()

    const mask = maskRef.current
    if (!mask) {
      const el = ref.current

      if (!el || !opts?.mask) return

      maskRef.current = IMask(el, opts)
        .on('accept', (value: IMask.InputMask<Opts>['value'], maskRef: IMask.InputMask<Opts>, e?: InputEvent) => {
          if (cbRefs.current.onAccept) cbRefs.current.onAccept(value, maskRef, e)
        })
        .on('complete', (value: IMask.InputMask<Opts>['value'], maskRef: IMask.InputMask<Opts>, e?: InputEvent) => {
          if (cbRefs.current.onComplete) cbRefs.current.onComplete(value, maskRef, e)
        })

      if (el.defaultValue !== maskRef.current.value && cbRefs.current.onAccept) {
        cbRefs.current.onAccept('', maskRef.current)
      }
    } else {
      mask.updateOptions(opts)
    }
  }, [opts, ref])

  useEffect(() => _destroyMask, [])

  return [ref, maskRef]
}
